import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false
//Vue.prototype.language ='en'

//fetch(process.env.BASE_URL + "config.json")
//  .then((json) => {
//    json().then((config) => {
//       Vue.prototype.$config = config
//       new Vue({
//         router,
//         vuetify,
//         render: (h) => h(App)
//       }).$mount("#app")
//    })
//})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
