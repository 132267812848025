<template>
  <v-main style="margin-top: 13px;">
    <v-card height="220" color="#2595AB">
      <div>
        <v-row>
          <v-col class="text-center">
            <h1 v-if="!isMobile()" style="color: white; margin-top: 40px; font-size: 80px">
              Specializzazioni
            </h1>
            <h1 v-if="isMobile()" style="color: white; margin-top: 60px; font-size: 40px">
              Specializzazioni
            </h1>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <br />
    <br />
    <v-row v-if="!isMobile()">
      <v-col> </v-col>
      <v-col> </v-col>
      <v-col style="font-size: 30px">
        <v-card align="center">
          <v-icon large>mdi-panorama-variant-outline</v-icon><br />
          <div style="text-align: center">ARTE</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 30px">
        <v-card align="center">
          <v-icon large>mdi-theater</v-icon><br />
          <div style="text-align: center">CINEMA</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 30px">
        <v-card align="center">
          <v-icon large>mdi-dance-ballroom</v-icon><br />
          <div style="text-align: center">DANZA</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 30px">
        <v-card align="center">
          <v-icon large>mdi-airplane</v-icon><br />
          <div style="text-align: center">TURISMO</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 30px">
        <v-card align="center">
          <v-icon large>mdi-glass-wine</v-icon><br />
          <div style="text-align: center">VINO</div>
        </v-card>
      </v-col>
      <v-col> </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row v-if="isMobile()">
      <v-col> </v-col>
      <v-col style="font-size: 15px">
        <v-card align="center">
          <v-icon large>mdi-panorama-variant-outline</v-icon><br />
          <div style="text-align: center">ARTE</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 15px">
        <v-card align="center">
          <v-icon large>mdi-theater</v-icon><br />
          <div style="text-align: center">CINEMA</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 15px">
        <v-card align="center">
          <v-icon large>mdi-dance-ballroom</v-icon><br />
          <div style="text-align: center">DANZA</div>
        </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row v-if="isMobile()">
      <v-col></v-col>
      <v-col style="font-size: 15px">
        <v-card align="center">
          <v-icon large>mdi-airplane</v-icon><br />
          <div style="text-align: center">TURISMO</div>
        </v-card>
      </v-col>
      <v-col style="font-size: 15px">
        <v-card align="center">
          <v-icon large>mdi-glass-wine</v-icon><br />
          <div style="text-align: center">VINO</div>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <br />
    <br />
    <br />
    <v-row>
      <v-col align="center" style="font-size: 30px"
        >Ecco alcuni esempi di altri settori in cui ho lavorato</v-col
      >
    </v-row>
    <br />
    <br />
    <br />
    <v-row v-if="!isMobile()">
      <v-col align="center">
        <v-card max-width="800" elevation="10">
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Alimentazione</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Educazione</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Religione</span>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Ambiente</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Famiglia</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Sport</span>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Clima</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Geografia</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Storia</span>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Cultura</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Multilinguismo</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Terrorismo</span>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <br />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isMobile()">
      <v-col align="center">
        <v-card max-width="800" elevation="10">
          <v-row>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Alimentazione</span>
              </v-card>
            </v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Ambiente</span>
              </v-card>
            </v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Clima</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Cultura</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Educazione</span>
              </v-card>
            </v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Famiglia</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Geografia</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Multilinguismo</span>
              </v-card>
            </v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Religione</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Sport</span>
              </v-card>
            </v-col>
            <v-col></v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Storia</span>
              </v-card>
            </v-col>
            <v-col>
              <br />
              <v-card width="200">
                <span style="font-size: 25px">Terrorismo</span>
              </v-card>
            </v-col>
          </v-row>
          <br />
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-parallax
      height="300"
      src="specializzazioni.jpg"
    >
    </v-parallax>
  </v-main>
</template>


<script>
export default {
  data: () => ({}),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
