<template>
  <v-main style="margin-top: 13px;">
    <v-card height="220" class="text-center" color="#2595AB">
      <div>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <h1 v-if="!isMobile()" style="color: white; margin-top: 40px; font-size: 80px">
              À propos
            </h1>
            <h1 v-if="isMobile()" style="color: white; margin-top: 60px; font-size: 50px">
              À propos
            </h1>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-card v-if="!isMobile()" style="width: 500px; margin-left: 100px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
          <v-card v-if="isMobile()" style="width: 500px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <div>
            <h1 style="font-size: 50px">Clarissa Bottos</h1>
            <p style="font-size: 19.5px">
              Je suis interprète et traductrice. Ma tâche est de construire des ponts linguistiques et culturels entre des gens qui parlent différentes langues. La passion pour les langues vient de ma mère qui est mon guide depuis l'enfance. Je serai toujours reconnaissant à mes parents parce qu'ils m'ont permis de voyager, de découvrir le monde, les différentes cultures et langues qui y sont liées. C'est ça, avec les études que j'ai faites, qui m'a aidé à devenir la professionnelle que je suis aujourd'hui. Si vous voulez en savoir plus, faites défiler vers le bas pour voir mes qualifications!
            </p>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <h1 v-if="!isMobile()" style="font-size: 50px">Éducation et formation</h1>
      <h1 v-if="isMobile()" style="font-size: 50px;text-align: center;">Éducation et formation</h1>

      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <v-timeline v-if="!isMobile()">
        <v-timeline-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${year.color}--text`"
              v-text="year.year"
            ></span>
          </template>

          <v-card color="white" style="width: 800px">
            <v-card-title
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </v-card-title>
            <v-container v-if="year.subtitle">
              <v-row>
                <v-col cols="12" md="10" style="color: black">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-list v-if="isMobile()">
        <v-list-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
        <v-row>
          <v-col align="">
          <v-container color="white">
            <div>
              <v-row>
                <v-col style="color: black; font-size: 15px">
                  {{ year.year }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
            <div
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </div>
            <div v-if="year.subtitle">
              <v-row>
                <v-col style="color: black; font-size: 20px">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
          </v-container>
          </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
      <v-col>
          <v-card style="width: 400px">
            <v-img eager src="clarissa-about-2.jpg" width="400px"> </v-img>
          </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-main>
</template>


<script>
export default {
  data: () => ({
    years: [
    {
        color: "#2595AB",
        year: "AVRIL 2023",
        title: "MEMBRE AGRÉGÉ AITI - ASSOCIATION ITALIENNE DES INTERPRÈTES ET TRADUCTEURS"
      },
      {
        color: "#2595AB",
        year: "DÉCEMBRE 2022",
        title: "PROFESSEUR DE MIUR AGRÉE POUR LA PRÉPARATION AUX EXAMENS CAMBRIDGE"
      },
    {
        color: "#2595AB",
        year: "OCTOBRE 2022",
        title: "INTERPRÈTE CERTIFIÉ VERSPEAK"
      },
      {
        color: "#2595AB",
        year: "2020 - 2022",
        title: "MAITRISE EN INTERPRETATION DE CONFÉRENCE",
        subtitle:
          "Università degli Studi Internazionali di Roma, " + " UNINT Rome (Italie)",
      },
      {
        color: "#2595AB",
        year: "JUIN 2022",
        title: "TRADUCTRICE BÉNÉVOLE Ted Conferences",
      },
      {
        color: "#2595AB",
        year: "MAI 2022",
        title: "ART ORATOIRE- COURS DE FORMATION AVANCÉE",
      },
      {
        color: "#2595AB",
        year: "MAI 2022",
        title: "ÉLOCUTION- COURS DE FORMATION AVANCÉE",
      },
      {
        color: "#2595AB",
        year: "MAI 2022",
        title: "COMMUNICATION INTERCULTURELLE- COURS DE FORMATION AVANCÉE",
      },
      {
        color: "#2595AB",
        year: "AVRIL 2022",
        title: "INTERPRÈTE CERTIFIÉ KUDO",
      },
      {
        color: "#2595AB",
        year: "AVRIL 2022",
        title:
          "INTERPRÈTE ET TRADUCTRICE HUMANITAIRE Translators without Borders",
      },
      {
        color: "#2595AB",
        year: "2021",
        title: "ERASMUS",
        subtitle:
          " Institute Of Intercultural Communication And Management, ISIT Paris (France)",
      },
      {
        color: "#2595AB",
        year: "AVRIL 2021",
        title: "TRADUCTRICE SDL TRADOS",
      },
      {
        color: "#2595AB",
        year: "2017 - 2020",
        title: "LICENCE EN LANGUES D’INTERPRÉTATION ET DE TRADUCTION",
        subtitle:
          "Università degli Studi Internazionali di Roma, UNINT Rome (Italie)",
      },
      {
        color: "#2595AB",
        year: "MAI 2016",
        title: "DELE",
        subtitle: "Instituto Cervantes, Rome (Italie)",
      },
      {
        color: "#2595AB",
        year: "AVRIL 2016",
        title: "DALF",
        subtitle: "Institut français Centre Saint-Louis, Rome (Italie)",
      },
      {
        color: "#2595AB",
        year: "MAI 2015",
        title: "FIRST",
        subtitle:
          "Cambridge University Press & Assessment English, Rome (Italie)",
      },
    ],
  }),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
