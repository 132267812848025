<template>
  <v-main style="margin-top: 13px;">
    <v-card height="220" class="text-center" color="#2595AB">
      <div>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <h1 v-if="!isMobile()" style="color: white; margin-top: 40px; font-size: 80px">
              About
            </h1>
            <h1 v-if="isMobile()" style="color: white; margin-top: 60px; font-size: 50px">
              About
            </h1>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-card v-if="!isMobile()" style="width: 500px; margin-left: 100px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
          <v-card v-if="isMobile()" style="width: 500px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <div>
            <h1 style="font-size: 50px">Clarissa Bottos</h1>
            <p style="font-size: 19.5px">
              I am an interpreter and translator. My task is to build linguistic
              and cultural bridges between people speaking different languages.
              The passion towards languages comes from my mother who has been my
              guide since childhood. I will always be grateful to my parents
              because they allowed me to travel, letting me discover the world,
              the various cultures and languages related to it. It was this,
              along with the studies I did, that helped me to become the
              professional I am today. If you want to know more, scroll down to
              see my qualifications!
            </p>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <h1 v-if="!isMobile()" style="font-size: 50px">Education and training</h1>
      <h1 v-if="isMobile()" style="font-size: 50px;text-align: center;">Education and training</h1>

      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <v-timeline v-if="!isMobile()">
        <v-timeline-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${year.color}--text`"
              v-text="year.year"
            ></span>
          </template>

          <v-card color="white" style="width: 800px">
            <v-card-title
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </v-card-title>
            <v-container v-if="year.subtitle">
              <v-row>
                <v-col cols="12" md="10" style="color: black">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-list v-if="isMobile()">
        <v-list-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
        <v-row>
          <v-col align="">
          <v-container color="white">
            <div>
              <v-row>
                <v-col style="color: black; font-size: 15px">
                  {{ year.year }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
            <div
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </div>
            <div v-if="year.subtitle">
              <v-row>
                <v-col style="color: black; font-size: 20px">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
          </v-container>
          </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
      <v-col>
          <v-card style="width: 400px">
            <v-img eager src="clarissa-about-2.jpg" width="400px"> </v-img>
          </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-main>
</template>


<script>
export default {
  data: () => ({
    years: [
      {
        color: "#2595AB",
        year: "APRIL 2023",
        title: "AITI ASSOCIATE - ITALIAN ASSOCIATION OF INTERPRETERS AND TRANSLATORS"
      },
      {
        color: "#2595AB",
        year: "DECEMBER 2022",
        title: "MIUR TEACHER ACCREDITED FOR CAMBRIDGE EXAMS"
      },
      {
        color: "#2595AB",
        year: "OCTOBER 2022",
        title: "VERSPEAK CERTIFIED INTERPRETER"
      },
      {
        color: "#2595AB",
        year: "2020 - 2022",
        title: "MASTER DEGREE IN CONFERENCE INTERPRETING",
        subtitle:
          "Università degli Studi Internazionali di Roma, UNINT Rome (Italy)",
      },
      {
        color: "#2595AB",
        year: "JUNE 2022",
        title: "VOLUNTEER TRANSLATOR Ted Conferences",
      },
      {
        color: "#2595AB",
        year: "MAY 2022",
        title: "PUBLIC SPEAKING- ADVANCED TRAINING COURSE",
      },
      {
        color: "#2595AB",
        year: "MAY 2022",
        title: "ELOCUTION- ADVANCED TRAINING COURSE",
      },
      {
        color: "#2595AB",
        year: "MAY 2022",
        title: "CROSS CULTURAL COMMUNICATION- ADVANCED TRAINING COURSE",
      },
      {
        color: "#2595AB",
        year: "APRIL 2022",
        title: "KUDO CERTIFIED INTERPRETER",
      },
      {
        color: "#2595AB",
        year: "APRIL 2022",
        title:
          "HUMANITARIAN INTERPRETER AND TRANSLATOR Translators without Borders",
      },
      {
        color: "#2595AB",
        year: "2021",
        title: "ERASMUS",
        subtitle:
          " Institute Of Intercultural Communication And Management, ISIT Paris (France)",
      },
      {
        color: "#2595AB",
        year: "APRIL 2021",
        title: "SDL TRADOS TRANSLATOR",
      },
      {
        color: "#2595AB",
        year: "2017 - 2020",
        title: "BACHELOR DEGREE IN LANGUAGES FOR INTERPRETING AND TRANSLATION",
        subtitle:
          "Università degli Studi Internazionali di Roma, UNINT Rome (Italy)",
      },
      {
        color: "#2595AB",
        year: "MAY 2016",
        title: "DELE",
        subtitle: "Instituto Cervantes, Rome (Italy)",
      },
      {
        color: "#2595AB",
        year: "APRIL 2016",
        title: "DALF",
        subtitle: "Institut français Centre Saint-Louis, Rome (Italy)",
      },
      {
        color: "#2595AB",
        year: "MAY 2015",
        title: "FIRST",
        subtitle:
          "Cambridge University Press & Assessment English, Rome (Italy)",
      },
    ],
  }),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
