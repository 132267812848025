<template>
  <v-main>
    <v-bottom-navigation v-if="!isMobile()" height="100">
      <v-col align-self="center">
        <v-row>
          <v-col>
            <v-img src="aiti-logo.jpg" style="margin-left: 20px" width="180">
            </v-img>
          </v-col>
          <v-col>
            <v-img src="logo.png" style="margin-left: 20px" width="180">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col align-self="center" style="margin-right: 150px">
        <v-btn color="white" router :to="home()">
          <span>HOME</span>
        </v-btn>

        <v-btn color="white" router :to="about()">
          <span v-if="this.language == 'en'">ABOUT</span>
          <span v-if="this.language == 'it'">CHI SONO</span>
          <span v-if="this.language == 'fr'">À PROPOS</span>
        </v-btn>

        <v-btn color="white" router :to="services()">
          <span v-if="this.language == 'en'">SERVICES</span>
          <span v-if="this.language == 'it'">SERVIZI</span>
          <span v-if="this.language == 'fr'">SERVICES</span>
        </v-btn>

        <v-btn color="white" router :to="specialisations()">
          <span v-if="this.language == 'en'">SPECIALISATIONS</span>
          <span v-if="this.language == 'it'">SPECIALIZZAZIONI</span>
          <span v-if="this.language == 'fr'">SPÉCIALISATIONS</span>
        </v-btn>
      </v-col>
      <v-col style="margin-right: -200px" align-self="center">
        <v-btn
          color="white"
          large
          router
          :to="this.homeEn"
          @click="setHome('en')"
        >
          <country-flag country="gb" size="normal" />
        </v-btn>
        <v-btn
          color="white"
          large
          router
          :to="this.homeIt"
          @click="setHome('it')"
        >
          <country-flag country="it" size="normal" />
        </v-btn>
        <v-btn
          color="white"
          large
          router
          :to="this.homeFr"
          @click="setHome('fr')"
        >
          <country-flag country="fr" size="normal" />
        </v-btn>
      </v-col>
    </v-bottom-navigation>
    <v-bottom-navigation v-if="isMobile()" height="150">
      <v-row align-content="center">
        <v-col></v-col>
        <v-col align-self="center">
          <v-row>
            <v-img src="aiti-logo.jpg" width="140" height="40"> </v-img>
          </v-row>
          <v-row>
            <v-img src="logo.png" width="140" height="40"> </v-img>
          </v-row>
        </v-col>
        <v-col align-self="center">
          <div class="text-center">
            <v-menu
              offset-y
              offset-x
              left
              :nudge-width="200"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn color="white" v-on="on">
                  <v-app-bar-nav-icon
                    v-if="on"
                    color="white"
                  ></v-app-bar-nav-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link
                    style="text-decoration: none; color: black"
                    :to="home()"
                    >Home</router-link
                  >
                </v-list-item>
                <v-list-item>
                  <router-link
                    v-if="this.language == 'en'"
                    style="text-decoration: none; color: black"
                    :to="about()"
                    >About</router-link
                  >
                  <router-link
                    v-if="this.language == 'it'"
                    style="text-decoration: none; color: black"
                    :to="about()"
                    >Chi sono</router-link
                  >
                  <router-link
                    v-if="this.language == 'fr'"
                    style="text-decoration: none; color: black"
                    :to="about()"
                    >À propos</router-link
                  >
                </v-list-item>
                <v-list-item>
                  <router-link
                    v-if="this.language == 'en'"
                    style="text-decoration: none; color: black"
                    :to="services()"
                    >Services</router-link
                  >
                  <router-link
                    v-if="this.language == 'it'"
                    style="text-decoration: none; color: black"
                    :to="services()"
                    >Servizi</router-link
                  >
                  <router-link
                    v-if="this.language == 'fr'"
                    style="text-decoration: none; color: black"
                    :to="services()"
                    >Services</router-link
                  >
                </v-list-item>
                <v-list-item>
                  <router-link
                    v-if="this.language == 'en'"
                    style="text-decoration: none; color: black"
                    :to="specialisations()"
                    >Specialisations</router-link
                  >
                  <router-link
                    v-if="this.language == 'it'"
                    style="text-decoration: none; color: black"
                    :to="specialisations()"
                    >Specializzazioni</router-link
                  >
                  <router-link
                    v-if="this.language == 'fr'"
                    style="text-decoration: none; color: black"
                    :to="specialisations()"
                    >Spécialisations</router-link
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    color="white"
                    icon
                    router
                    :to="this.homeEn"
                    @click="setHome('en')"
                  >
                    <country-flag
                      style="margin-left: 25px"
                      country="gb"
                      size="normal"
                    />
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    color="white"
                    icon
                    router
                    :to="this.homeIt"
                    @click="setHome('it')"
                  >
                    <country-flag
                      style="margin-left: 25px"
                      country="it"
                      size="normal"
                    />
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    color="white"
                    icon
                    router
                    :to="this.homeFr"
                    @click="setHome('fr')"
                  >
                    <country-flag
                      style="margin-left: 25px"
                      country="fr"
                      size="normal"
                    />
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-bottom-navigation>
  </v-main>
</template>
<script>
import CountryFlag from "vue-country-flag";
export default {
  data: () => ({
    servicesEn: "/ServicesEn",
    aboutEn: "/AboutEn",
    homeEn: "/",
    specialisationsEn: "/SpecialisationsEn",
    servicesIt: "/ServicesIt",
    aboutIt: "/AboutIt",
    homeIt: "/HomeIt",
    specialisationsIt: "/SpecialisationsIt",
    specialisationsFr: "/SpecialisationsFr",
    servicesFr: "/ServicesFr",
    aboutFr: "/AboutFr",
    homeFr: "/HomeFr",
    language: "en",
  }),
  components: {
    CountryFlag,
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    setHome(lang) {
      this.language = lang;
    },
    home() {
      if (this.language == "en") {
        return this.homeEn;
      }
      if (this.language == "it") {
        return this.homeIt;
      }
      if (this.language == "fr") {
        return this.homeFr;
      }
    },
    about() {
      if (this.language == "en") {
        return this.aboutEn;
      }
      if (this.language == "it") {
        return this.aboutIt;
      }
      if (this.language == "fr") {
        return this.aboutFr;
      }
    },
    services() {
      if (this.language == "en") {
        return this.servicesEn;
      }
      if (this.language == "it") {
        return this.servicesIt;
      }
      if (this.language == "fr") {
        return this.servicesFr;
      }
    },
    specialisations() {
      if (this.language == "en") {
        return this.specialisationsEn;
      }
      if (this.language == "it") {
        return this.specialisationsIt;
      }
      if (this.language == "fr") {
        return this.specialisationsFr;
      }
    },
  },
};
</script>
<style>
</style>
