<template>
  <v-main>
    <v-parallax src="background.png">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h4 style="color: black; font-size: 30px">Clarissa Bottos</h4>
          <div style="color: black; font-size: 50px">
            Interpreter & Translator
          </div>
          <div style="color: black">
            Translating your voice into my languages
          </div>
          <div style="color: black">Italian | French | English</div>
          <br />
          <br />
          <v-btn href="#readMore" color="black"
            ><div style="color: white">Read more</div></v-btn
          >
        </v-col>
      </v-row>
    </v-parallax>
    <div id="readMore">
      <v-card
        height="380"
        class="text-center"
        color="#2595AB"
        v-if="!isMobile()"
      >
        <br />
        <br />
        <br />
        <br />
        <v-row style="color: white; font-size: 20px">
          <v-col>
            <h3>Simultaneous, consecutive and liaison interpreting</h3>
            Interpreter for conferences, conventions, expos, meetings, training
            courses and trade shows both on-site and remotely
          </v-col>
          <v-col>
            <h3>Dialogues adaptation, localization and translation</h3>
            Translator for legal/judicial, literary/editorial,
            technical/scientific texts, dialogues adaptation and localization
          </v-col>
        </v-row>
        <v-row style="color: white">
          <v-col>
            <h3>Language courses</h3>
            Both for companies and individuals, from the most basic to more
            advanced proficiency levels </v-col
          ><v-col>
            <h3>Transcription</h3>
            Audio and video files transcription
          </v-col>
        </v-row>
      </v-card>

      <v-card
        height="530"
        class="text-center"
        color="#2595AB"
        v-if="isMobile()"
      >
        <v-row style="color: white">
          <v-col>
            <h3>Simultaneous, consecutive and liaison interpreting</h3>
            Interpreter for conferences, conventions, expos, meetings, training
            courses and trade shows both on-site and remotely
          </v-col>
          <v-col>
            <h3>Dialogues adaptation, localization and translation</h3>
            Translator for legal/judicial, literary/editorial,
            technical/scientific texts, dialogues adaptation and localization
          </v-col>
        </v-row>
        <v-row style="color: white">
          <v-col>
            <h3>Language courses</h3>
            Both for companies and individuals, from the most basic to more
            advanced proficiency levels </v-col
          ><v-col>
            <h3>Transcription</h3>
            Audio and video files transcription
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-row>
      <v-col>
        <v-container>
          <v-col v-if="!isMobile()">
            <v-row>
              <v-col>
                <v-card style="margin-left: " width="270">
                  <v-img src="clarissa2-home.jpg" width="270px" eager> </v-img>
                </v-card>
              </v-col>
              <v-col>
                <v-card style="margin-left: " width="270">
                  <v-img src="clarissa-whoami.jpg" width="270px" eager> </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isMobile()" align="center">
            <v-card width="300">
              <v-img src="clarissa2-home.jpg" width="300px" eager> </v-img>
            </v-card>
            <v-card width="300">
              <v-img src="clarissa-whoami.jpg" width="300px" eager> </v-img>
            </v-card>
          </v-col>
        </v-container>
      </v-col>
      <v-col>
        <v-container style="margin-top: 50px">
          <h1>Hi!</h1>
          <h1>I’m Clarissa Bottos</h1>
          <br />
          I’m curious and determined. Always looking for new challenges.
          <br />
          <br />

          I’m <strong>attentive</strong>, <strong>efficient</strong> and
          <strong>punctual</strong> at work.
          <br />
          <br />
          <div>
            I live in Rome, but I’m a citizen of the world. I've lived in Paris
            and traveled on five continents. I’m available for national and
            international travel.
          </div>
        </v-container>
        <v-row v-if="!isMobile()" style="margin-left: 3px">
          <v-col>
            <v-btn
              href="https://www.linkedin.com/in/clarissa-bottos-4985a01b9/"
              color="black"
              ><div style="color: white">Get to know me better</div></v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="isMobile()">
          <v-col align="center">
            <v-btn
              href="https://www.linkedin.com/in/clarissa-bottos-4985a01b9/"
              color="black"
              ><div style="color: white">Get to know me better</div></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div style="margin-top: 20px">
      <v-card
        v-if="!isMobile()"
        height="200"
        class="text-center"
        color="#2595AB"
      >
      <br>
      <br>
        <v-row>
          <v-col>
          <v-col class="text-center" >
            <h4 style="color: white; font-size: 25px;">
              "One language sets you on a corridor for life.
            </h4>
            <h4 style="color: white; font-size: 25px;">
              Two languages open
              every door along the way."
            </h4>
          </v-col>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="isMobile()"
        height="120"
        class="text-center"
        color="#2595AB"
      >
      <v-row></v-row>
        <v-row align="center" justify="center" align-content="center" class="text-center">
          <v-col>
          <v-col class="text-center" >
            <h4 style="color: white;">
              "One language sets you on a corridor for life.
            </h4>
            <h4 style="color: white;">
              Two languages open
              every door along the way."
            </h4>
          </v-col>
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-card>
    </div>
    <br />
    <br />
    <v-container
      v-if="!isMobile()"
      height="100"
      class="text-center"
      color="#2595AB"
    >
      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col class="text-center">
          <h3 style="color: black; font-size: 50px">I worked for</h3>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="isMobile()"
      height="100"
      class="text-center"
      color="#2595AB"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h3 style="color: black; font-size: 30px">I worked for</h3>
        </v-col>
      </v-row>
    </v-container>

    <v-carousel
      cycle
      height="300"
      hide-delimiter-background
      :show-arrows="false"
      eager
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
        <v-sheet height="100%">
          <v-row class="fill-height" align="center" justify="center">
            <v-card class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[0]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>
            <v-card v-if="!isMobile()" class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[1]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>

            <v-card v-if="!isMobile()" class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[2]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <br />
    <v-parallax style="margin-bottom: -17px" height="250" src="background.png">
      <v-row align="center" justify="center">
        <v-col class="text-center" style="color: black">
          <div style="font-size: 25px" v-if="!isMobile()">
            Want to create something great together?
          </div>
          <h3 v-if="isMobile()">Want to create something great together?</h3>
          <div>Let’s make it happen.</div>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row style="margin-top: 5px;">
      <v-col> </v-col>
      <v-col>
          <v-card style="width: 400px">
            <v-img eager src="clarissa-fao.jpeg" width="400px"> </v-img>
          </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-main>
</template>


<script>
export default {
  data: () => ({
    slides: [
      ["logo-fao.png", "villa-medici.jpg", "italian-chef-academy.png"],
      ["villa-medici.jpg", "italian-chef-academy.png", "logo-ita-dance.png"],
      ["italian-chef-academy.png", "logo-ita-dance.png", "logo-amb-peru.png"],
      ["logo-ita-dance.png", "logo-amb-peru.png", "logoAmb-Italie.jpg"],
      ["logo-amb-peru.png", "logoAmb-Italie.jpg", "fit-csl.jpg"],
      ["logoAmb-Italie.jpg", "fit-csl.jpg", "logo-fao.png"],
      ["fit-csl.jpg", "logo-fao.png", "villa-medici.jpg"],
      ["logo-fao.png", "villa-medici.jpg","italian-chef-academy.png"]
    ],
  }),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
