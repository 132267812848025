<template>
  <v-main style="margin-top: 13px;">
    <div style="color: #2595ab">
      <v-card v-if="!isMobile()" height="450" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 80px">Services</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Dialogues adaptation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Interpreting</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Language courses</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Transcription</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Translation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </v-card>
      <v-card v-if="isMobile()" height="650" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 50px">Services</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Dialogues adaptation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Interpreting</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Language courses</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Transcription</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Translation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-col class="text-center">
          <div v-if="!isMobile()">
            <h1 style="font-size: 50px">My Languages</h1>
          </div>
          <div v-if="isMobile()">
            <h1 style="font-size: 30px">My Languages</h1>
          </div>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile()">
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italian
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          French
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          English
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Spanish
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>

    <div v-if="isMobile()">
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italian
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          French
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          English
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Spanish
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
    </div>
    <br />
    <v-parallax height="300" src="adattamento-dialoghi.jpg"> </v-parallax>
    <br />
    <br />
    <v-row id="dialoguesAdaptation">
      <v-col>
        <v-row>
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Dialogues adaptation</h1>
            <div v-if="!isMobile()" style="font-size: 20px;">Italian</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">Italian</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            First, <strong>translating</strong>. From the translation point of
            view, the contents of the dialogues should be as close as possible
            to the original.
          </p>
          <br />
          <p>
            Then, <strong>adapting</strong>. From the adaptation point of view,
            the lines must best follow the actors’ lip movements, to give the
            illusion that en/fr/it is the native language of the video.
          </p>
          <br />
          <p>
            After that, the script will be recorded in the room by professional
            dubbers –
            <strong
              >I’m attending the Master in Translation and Adaptation of
              audiovisual and multimedia works for dubbing and subtitle</strong
            >
            - and transformed into an audio track, which will then be edited and
            mixed. The film dubbed in en/fr/it will be ready for distribution!
          </p>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="interpretariato.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="interpreting">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Interpreting</h1>
            <div v-if="!isMobile()" style="font-size: 20px">English | French | Italian</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">English | French | Italian</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            Interpreting is a translational activity in which one produces a
            first and final target-language output on the basis of a one-time
            exposure to an expression in a source language.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Consecutive interpreting.</strong> In consecutive
                interpreting (CI), the interpreter starts to interpret after the
                speaker pauses; thus much more time (perhaps double) is needed.
                Customarily, such an interpreter will sit or stand near the
                speaker.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Liaison interpreting.</strong> The liaison interpreting
                technique is performed in a two-way manner and it’s used in
                business negotiations, without note-taking and using concise
                sentences.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Phone interpreting.</strong> Video remote interpreting
                (VRI) is a videotelecommunication service that uses devices such
                as web cameras or videophones to provide spoken language
                interpreting services. This is done through a remote or offsite
                interpreter.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Remote interpreting.</strong> Also referred to as
                "over-the-phone interpreting", "telephonic interpreting," and
                "tele-interpreting," telephone interpreting enables
                interpretation via telephone. Telephone interpreting can be used
                in community settings as well as conference settings.
              </p>
            </li>
            <li>
              <p>
                <strong>Simultaneus interpreting.</strong> Simultaneous
                interpreting is a mode of interpreting in which the speaker
                makes a speech and the interpreter reformulates the speech into
                a language his audience understands at the same time (or
                simultaneously).
              </p>
            </li>
            <li>
              <p>
                <strong>Whispered interpreting.</strong> Since time immemorial,
                whispered interpretation has been used, known in the trade by
                the French term chuchotage. To avoid disturbing the original
                speaker and those present listening to the original speaker, the
                interpreter's voice is kept at a low volume. To do this, the
                interpreter and the person requiring interpretation must sit or
                stand in close proximity to one another.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="corsi-di-lingua.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="languageCourses">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Language courses</h1>
            <div>English | French | Italian | Spanish</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div style="align-self: center;">
            <p>
              If you’re struggling to learn a new language, breathe, you’re not
              alone. Adults famously find language learning more difficult than
              children, whose super-flexible brains actually grow the
              connections necessary to learn an additional language. But, why is
              it so hard to learn a foreign language, anyway? Put simply, it’s
              hard because it challenges both your mind (your brain has to
              construct new cognitive frameworks) and time (it requires
              sustained, consistent practice). Here, five undeniable reasons to
              learn a new language:
            </p>
            <ul>
              <li>
                <strong
                  >A whole new language means access to a whole new
                  culture</strong
                >
              </li>
              <br />
              <li><strong>Boost your confidence</strong></li>
              <br />
              <li>
                <strong>Enhance your brain power</strong>
              </li>
              <br />
              <li><strong>Expand your career opportunities</strong></li>
              <br />
              <li><strong>Travel the world</strong></li>
            </ul>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <v-parallax height="570" src="trascrizione.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="transcription">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Transcription</h1>
            <div v-if="!isMobile()" >English | French | Italian</div>
            <div style="text-align: center;" v-if="isMobile()">English | French | Italian</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div>
            <p>
              It’s the systematic representation of spoken language in written
              form. Transcription is an essential part of the methodologies of
              phonetics, conversation analysis, dialectology, and
              sociolinguistics. Common examples for transcriptions outside
              academia are the proceedings of a
              <strong>court hearing such as a criminal trial</strong> (by a
              court reporter) or a
              <strong>physician's recorded voice notes</strong> (medical
              transcription).
            </p>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="500" src="traduzione.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="translation">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Translation</h1>
            <div v-if="!isMobile()" style="font-size: 20px">English | French | Italian</div>
            <div v-if="isMobile()" style="font-size: 20px;text-align: center;">English | French | Italian</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            Translation is the communication of the meaning of a source-language
            text by means of an equivalent target-language text.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Administrative translation.</strong> In the realm of
                translation, administrative refers to the translation of
                management texts we often see being used in organizations –
                whether huge corporations or regional businesses.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Commercial translation.</strong> The types of texts in
                the translation of commercial documents could include business
                correspondence, reports, tender documents, company accounts,
                memos and so on.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Financial translation.</strong> It is about financial
                documents like bank records, statements, account statements and
                more – which sometimes need to be translated to make it easier
                for the target audience to comprehend.
              </p>
            </li>
            <li>
              <p>
                <strong>Judicial translation.</strong> We basically refer to the
                activity of translating court documents like depositions,
                minutes of meetings, expert testimonies, witness testimonies,
                judgments, letters rogatory, interviews and more – basically,
                activities related to cases.
              </p>
            </li>
            <li>
              <p>
                <strong>Legal translation.</strong> It involves birth
                certificate translations and marriage certificate translations,
                translating contracts, agreements, treaties, memorandums and
                wills.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Literary translation.</strong> It refers to the
                translation of literary works like stories, novels, poems, and
                plays.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Medical translation.</strong> It involves any medical
                content that is patient related, like labels, packaging,
                instructions, or software, and content that is product related,
                like research papers, clinical trial paperwork, quality
                management certificates and the like, usually needs translation.
              </p>
            </li>
            <li>
              <p>
                <strong>Script translation.</strong> It is vital nowadays
                because of many popular movies and TV shows comming out of
                Hollywood and being dubbed into several languages and released
                worldwide.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Technical translation.</strong> It involves user guides,
                manuals, online help text, instruction booklets, training
                materials and videos, marketing materials for technical fields
                like manufacturing, science or engineering.
              </p>
            </li>
            <li>
              <p>
                <strong>Website translation.</strong> It is about website copy,
                subtitles for videos on your web pages, and any documents you
                have there.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax
      style="margin-bottom: -65px"
      height="300"
      src="ultima-servizi.jpg"
    >
    </v-parallax>
    <br />
    <br />
  </v-main>
</template>


<script>
export default {
  data: () => ({}),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
