<template>
  <v-main style="margin-top: 13px;">
    <div style="color: #2595ab">
      <v-card v-if="!isMobile()" height="450" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 80px">Servizi</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Adattamento dialoghi</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Interpretariato</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Corsi di lingua</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Trascrizione</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Traduzione</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </v-card>
      <v-card v-if="isMobile()" height="650" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 50px">Servizi</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Adattamento dialoghi</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Interpretariato</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Corsi di lingua</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Trascrizione</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Traduzione</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-col class="text-center">
          <div v-if="!isMobile()">
            <h1 style="font-size: 50px">Le mie lingue</h1>
          </div>
          <div v-if="isMobile()">
            <h1 style="font-size: 30px">Le mie lingue</h1>
          </div>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile()">
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italiano
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Francese
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Inglese
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Spagnolo
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>

    <div v-if="isMobile()">
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italiano
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Francese
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Inglese
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Spagnolo
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
    </div>
    <br />
    <v-parallax height="300" src="adattamento-dialoghi.jpg"> </v-parallax>
    <br />
    <br />
    <v-row id="dialoguesAdaptation">
      <v-col>
        <v-row>
          <v-col></v-col>
          <v-col>
            <h1 v-if="!isMobile()" style="font-size: 60px">Adattamento dialoghi</h1>
            <h1 v-if="isMobile()" style="font-size: 60px; text-align: center;">Adattamento dialoghi</h1>
            <div v-if="!isMobile()" style="font-size: 20px;">Italiano</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">Italiano</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            Prima, <strong>si traduce</strong>. Dal punto di vista traduttivo, i contenuti dei dialoghi devono essere il più possibile fedeli all'originale.
          </p>
          <br />
          <p>
            Poi, <strong>si adatta</strong>. Dal punto di vista dell'adattamento, le frasi devono seguire i movimenti della bocca degli attori, per dare l'illusione che quella sia la lingua nativa del video.
          </p>
          <br />
          <p>
            Dopodiché, la sceneggiatura sarà registrata in sala da doppiatori professionisti - 
            <strong
              >sto frequentando il Master in Traduzione e Adattamento di opere audiovisive e multimediali per il doppiaggio e sottotitolaggio</strong
            >
            - e trasformata in una traccia audio, che sarà poi modificata e mixata. Il film doppiato in lingua sarà pronto per la distribuzione!
          </p>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="interpretariato.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="interpreting">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Interpretariato</h1>
            <div v-if="!isMobile()" style="font-size: 20px">Inglese | Francese | Italiano</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">Inglese | Francese | Italiano</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            L'interpretariato è un'attività traslazionale in cui si produce un primo output finale della lingua di destinazione sulla base di un'esposizione una tantum a un'espressione in una lingua di origine.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Interpretazione consecutiva.</strong> Nell'interpretazione consecutiva (IC), l'interprete inizia a interpretare dopo le pause dell'oratore; quindi è necessario molto più tempo (forse doppio). Abitualmente, tale interprete siederà o starà vicino all'oratore.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Interpretazione di trattativa.</strong> La tecnica di interpretazione di trattativa viene eseguita in modo bidirezionale ed è utilizzata nelle trattative commerciali, senza prendere appunti e utilizzando frasi concise.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Interpretazione telefonica.</strong> Anche denominata "over-the-phone interpreting," "interpretazione telefonica," e "tele-interpretazione," l'interpretazione telefonica consente l'interpretazione via telefono. L'interpretazione telefonica può essere utilizzata nelle conferenze.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Interpretazione da remoto.</strong> L'interpretazione video remoto (IVR) è un servizio di videotelecomunicazione che utilizza dispositivi come telecamere per fornire servizi di interpretariato della lingua parlata. Questo viene fatto attraverso un interprete remoto o fuori sede.
              </p>
            </li>
            <li>
              <p>
                <strong>Interpretazione simultanea.</strong> L'interpretazione simultanea è una modalità di interpretazione in cui l'oratore fa un discorso e l'interprete riformula il discorso in una lingua che il suo pubblico comprende allo stesso tempo (o contemporaneamente).
                </p>
              </li>
            <li>
              <p>
                <strong>Interpretazione sussurrata.</strong> Da tempi immemori, è stata usata l'interpretazione sussurrata, conosciuta nel commercio con il termine francese chuchotage. Per evitare di disturbare l'oratore originale e i presenti che ascoltano l'oratore originale, la voce dell'interprete è tenuta a un basso volume. Per fare questo, l'interprete e la persona che richiede l'interpretazione devono sedersi o stare in prossimità l'uno dell'altro.
            </p>
              </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="corsi-di-lingua.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="languageCourses">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 70px">Corsi di</h1><h1 style="font-size: 80px"> lingua</h1>
            <div>Inglese | Francese | Italiano | Spagnolo</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div style="align-self: center;">
            <p>
              Se stai lottando con te stesso per imparare una nuova lingua, respira, non sei solo. Gli adulti trovano l'apprendimento delle lingue più difficile rispetto ai bambini, il cui cervello super-flessibile sviluppa le connessioni necessarie per imparare un'altra lingua. Ma, perché è così difficile imparare una nuova lingua? In parole povere, è difficile perché sfida sia la mente (il tuo cervello deve costruire nuovi quadri cognitivi) che il tempo (richiede una pratica sostenuta e coerente). Ecco cinque motivi per imparare una nuova lingua:
            </p>
              <ul>
              <li>
                <strong
                  >Una lingua nuova significa accesso a una cultura nuova</strong
                >
              </li>
              <br />
              <li><strong>Aumenta la tua fiducia</strong></li>
              <br />
              <li>
                <strong>Migliora il tuo cervello</strong>
              </li>
              <br />
              <li><strong>Espande le tue oppurtinità di carriera</strong></li>
              <br />
              <li><strong>Ti permette di viaggiare per il mondo</strong></li>
            </ul>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <v-parallax height="570" src="trascrizione.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="transcription">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Trascrizione</h1>
            <div v-if="!isMobile()" >Inglese | Francese | Italiano</div>
            <div style="text-align: center;" v-if="isMobile()">Inglese | Francese | Italiano</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div>
            <p>
              È la rappresentazione sistematica del linguaggio parlato in forma scritta. La trascrizione è una parte essenziale delle metodologie di fonetica, analisi della conversazione, dialettologia e sociolinguistica. Esempi comuni per le trascrizioni al di fuori del mondo accademico sono i
              <strong>processi di un'udienza giudiziaria come un processo penale</strong>(trascrizione giudiziaria) o 
              <strong>note vocali registrate di un medico</strong> (trascrizione medica).
            </p>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="500" src="traduzione.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="translation">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Traduzione</h1>
            <div v-if="!isMobile()" style="font-size: 20px">Inglese | Francese | Italiano</div>
            <div v-if="isMobile()" style="font-size: 20px;text-align: center;">Inglese | Francese | Italiano</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            La traduzione è la comunicazione del significato di un testo in lingua di origine mediante un testo in lingua di destinazione equivalente.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Traduzione amministrativa.</strong> Nel regno della traduzione, amministrativa si riferisce alla traduzione di testi di gestione che vediamo spesso utilizzati nelle organizzazioni - sia in grandi aziende che imprese regionali.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduzione commerciale.</strong> I tipi di testi nella traduzione di documenti commerciali potrebbero includere la corrispondenza commerciale, le relazioni, i documenti di gara, i conti delle società, i promemoria e così via.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduzione finanziaria.</strong> Si tratta di documenti finanziari come documenti bancari, estratti conto, e altro ancora - che a volte devono essere tradotti per rendere più facile la comprensione per il pubblico.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduzione giuridica.</strong> Ci riferiamo fondamentalmente all'attività di traduzione di documenti giudiziari come deposizioni, verbali delle riunioni, testimonianze di esperti, testimonianze di testimoni, sentenze, rogatorie, interviste e altro ancora - fondamentalmente, attività relative ai casi.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduzione legale.</strong> Si tratta di traduzioni di certificati di nascita e di traduzioni di certificati di matrimonio, traduzioni di contratti, accordi, trattati, memorandum e testamenti.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduzione letteraria.</strong> Si riferisce alla traduzione di opere letterarie come storie, romanzi, poesie e opere teatrali.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduzione medica.</strong> Si tratta di qualsiasi contenuto medico legato al paziente, come le etichette, l'imballaggio, le istruzioni, o legato al prodotto, come i documenti di ricerca, i documenti di prova clinica, i certificati di gestione della qualità e simili.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduzione di sceneggiatura.</strong> E' di vitale importanza al giorno d'oggi poiché molti film, serie televisive e show si stanno avvicinando da Hollywood al nostro paese e necessitano di doppiaggio o sottotitolo.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduzione tecnica.</strong> Si tratta di guide per l'utente, manuali, testi, opuscoli di istruzioni, materiali di formazione e video, materiali di marketing per settori tecnici come la produzione, la scienza o l'ingegneria.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduzione di siti web.</strong> Si tratta di traduzione di siti web, pagine web e documenti web.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax
      style="margin-bottom: -65px"
      height="300"
      src="ultima-servizi.jpg"
    >
    </v-parallax>
    <br />
    <br />
  </v-main>
</template>


<script>
export default {
  data: () => ({}),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
