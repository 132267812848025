<template>
  <v-main style="margin-top: 13px;">
    <div style="color: #2595ab">
      <v-card v-if="!isMobile()" height="450" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 80px">Services</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"  style="word-break: break-word"
                    >Adaptation des dialogues</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Interprétation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Cours de langue</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col></v-col>
            <v-col>
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Transcription</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col>
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card>
                  <v-card-title class="justify-center"
                    >Traduction</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </v-card>
      <v-card v-if="isMobile()" height="650" color="#2595AB">
        <div>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <br />
              <h1 style="color: white; font-size: 50px">Services</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#dialoguesAdaptation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Adaptation des dialogue</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#interpreting"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Interprétation</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#languageCourses"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Cours de langue</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#transcription"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Transcription</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
            </v-row>
            <v-row>
            <v-col align="center">
              <a
                itemprop="url"
                href="#translation"
                target="_self"
                style="text-decoration: none"
              >
                <v-card max-width="300">
                  <v-card-title class="justify-center"
                    >Traduction</v-card-title
                  >
                </v-card>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-col class="text-center">
          <div v-if="!isMobile()">
            <h1 style="font-size: 50px">Mes langues</h1>
          </div>
          <div v-if="isMobile()">
            <h1 style="font-size: 30px">Mes langues</h1>
          </div>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile()">
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italien
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Français
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Anglais
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Espagnol
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>

    <div v-if="isMobile()">
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">A</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Italien
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">B</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Français
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
      <v-row>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">C</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Anglais
        </div>
      </v-col>
      <v-col></v-col>
      <v-col>
        <h1 style="font-size: 150px; color: #2595ab; text-align: center">D</h1>
        <div style="font-size: 30px; margin-top: -60px; text-align: center">
          Espagnol
        </div>
      </v-col>
      <v-col></v-col>
      </v-row>
    </div>
    <br />
    <v-parallax height="300" src="adattamento-dialoghi.jpg"> </v-parallax>
    <br />
    <br />
    <v-row id="dialoguesAdaptation">
      <v-col>
        <v-row>
          <v-col align="center">
            <h1 style="font-size: 60px">Adaptation des dialogues</h1>
            <div v-if="!isMobile()" style="font-size: 20px;">Italien</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">Italien</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
          Tout d'abord, <strong>traduire</strong>. Du point de vue de la traduction, le contenu des dialogues devrait être aussi proche que possible de l’original.
          </p>
          <br />
          <p>
            Ensuit, <strong>adapter</strong>. Du point de vue de l’adaptation, les lignes doivent mieux suivre les mouvements des lèvres des acteurs, pour donner l’illusion que c'est la langue maternelle de la vidéo.
          </p>
          <br />
          <p>
            Après cela, le scénario sera enregistré dans la salle par des doubleurs professionnels–
            <strong
              >je participe au Master en Traduction et Adaptation d’œuvres audiovisuelles et multimédias pour le doublage et le sous-titrage</strong>
            - et transformé en une piste audio, qui sera ensuite éditée et mixée. Le film doublé en langue sera prêt pour la distribution !
          </p>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="interpretariato.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="interpreting">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Interprétation</h1>
            <div v-if="!isMobile()" style="font-size: 20px">Anglais | Français | Italien</div>
            <div v-if="isMobile()" style="font-size: 20px; text-align: center;">Anglais | Français | Italien</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            L’interprétation est une activité translationnelle dans laquelle on produit une première et une dernière sortie en langue cible sur la base d’une exposition ponctuelle à une expression dans un langage source.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong> Interprétation consécutive.</strong> En interprétariat consécutif (IC), l’interprète commence à interpréter après que le locuteur se soit arrêté; il faut donc beaucoup plus de temps (peut-être double). Habituellement, un tel interprète s’assoit ou se tient près du locuteur.
            </p>
              </li>
            <br />
            <li>
              <p>
                <strong>Interprétation de liaison.</strong> La technique d’interprétation de liaison est effectuée de manière bidirectionnelle et elle est utilisée dans les négociations commerciales, sans prise de notes et en utilisant des phrases concises.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Interprétation téléphonique.</strong> Aussi appelé «interprétation par téléphone», «interprétation téléphonique» et «téléinterprétation», l’interprétation téléphonique permet l’interprétation par téléphone. L’interprétation par téléphone peut être utilisée dans les contextes communautaires et de conférence.
                </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Interprétation à distance.</strong> L’interprétation vidéo à distance (IVD) est un service de vidéotélécommunication qui utilise des appareils comme des caméras Web ou des vidéophones pour fournir des services d’interprétation orale. Cela se fait par l’intermédiaire d’un interprète à distance ou hors site.
              </p>
            </li>
            <li>
              <p>
                <strong>Interprétation simultanée.</strong> L’interprétation simultanée est un mode d’interprétation dans lequel l’orateur fait un discours et l’interprète reformule le discours en une langue que son auditoire comprend en même temps (ou simultanément).
              </p>
            </li>
            <li>
              <p>
                <strong>Interprétation chuchotée.</strong> Depuis des temps immémoriaux, l’interprétation chuchotée est utilisée, connue dans le commerce par le terme français chuchotage. Pour éviter de déranger le locuteur original et ceux qui l’écoutent, la voix de l’interprète est maintenue à un faible volume. Pour ce faire, l’interprète et la personne nécessitant une interprétation doivent s’asseoir ou se tenir à proximité les uns des autres.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="300" src="corsi-di-lingua.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="languageCourses">
          <v-col align="center">
            <h1 style="font-size: 75px">Cours de</h1>
            <h1 style="font-size: 75px">langue</h1>
            <div>Anglais | Français | Italien | Espagnol</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div style="align-self: center;">
            <p>
              Si vous avez du mal à apprendre une nouvelle langue, respirez, vous n’êtes pas seul. Les adultes ont la réputation de trouver l’apprentissage des langues plus difficile que les enfants, dont les cerveaux super-flexibles développent en fait les connexions nécessaires pour apprendre une langue supplémentaire. Mais, pourquoi est-il si difficile d’apprendre une langue étrangère, de toute façon? En termes simples, c’est difficile parce que cela défie à la fois votre esprit (votre cerveau doit construire de nouveaux cadres cognitifs) et le temps (cela nécessite une pratique soutenue et cohérente). Voici cinq raisons indéniables pour apprendre une nouvelle langue:
            </p>
            <ul>
              <li>
                <strong
                  >Une toute nouvelle langue signifie l’accès à une toute nouvelle culture</strong
                >
              </li>
              <br />
              <li><strong>Rehausser votre confiance</strong></li>
              <br />
              <li>
                <strong>Améliorer la puissance de votre cerveau</strong>
              </li>
              <br />
              <li><strong>Élargir vos possibilités de carrière</strong></li>
              <br />
              <li><strong>Parcourir le monde</strong></li>
            </ul>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <v-parallax height="570" src="trascrizione.jpg"> </v-parallax>
    <v-row>
      <v-col>
        <v-row id="transcription">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Transcription</h1>
            <div v-if="!isMobile()" >Anglais | Français | Italien</div>
            <div style="text-align: center;" v-if="isMobile()">Anglais | Français | Italien</div>

          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
          <v-col>
          <div>
            <p>
              C’est la représentation systématique de la langue parlée sous forme écrite. La transcription est un élément essentiel des méthodologies de la phonétique, de l’analyse des conversations, de la dialectologie et de la sociolinguistique. Des exemples courants de transcriptions à l’extérieur du milieu universitaire sont les
              <strong>délibérations d’une audience judiciaire, procès criminel</strong> (par un sténographe judiciaire) ou les
              <strong>notes vocales enregistrées par un médecin</strong> (transcription médicale).
            </p>
          </div>
          </v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax height="500" src="traduzione.jpg"> </v-parallax>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-row id="translation">
          <v-col></v-col>
          <v-col>
            <h1 style="font-size: 60px">Traduction</h1>
            <div v-if="!isMobile()" style="font-size: 20px">Anglais | Français | Italien</div>
            <div v-if="isMobile()" style="font-size: 20px;text-align: center;">Anglais | Français | Italien</div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-col></v-col>
        <v-col>
          <p>
            La traduction est la communication du sens d’un texte en langue source au moyen d’un texte équivalent en langue cible.
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Traduction administrative.</strong> Dans le domaine de la traduction, l’administration désigne la traduction de textes de gestion que nous voyons souvent être utilisés dans les organisations – qu’il s’agisse de grandes sociétés ou d’entreprises régionales.
           </p>
              </li>
            <br />
            <li>
              <p>
                <strong>Traduction commerciale.</strong> Les types de textes dans la traduction des documents commerciaux pourraient inclure la correspondance d’affaires, les rapports, les documents d’appel d’offres, les comptes d’entreprise, les notes de service etc.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduction financière.</strong> Il s’agit de documents financiers comme les relevés bancaires, les relevés de compte et plus encore – qui doivent parfois être traduits pour faciliter la compréhension du public cible.
            </p>
            </li>
                <li>
              <p>
                <strong>Traduction judiciaire.</strong> Nous faisons essentiellement référence à la traduction de documents judiciaires comme les dépositions, les procès-verbaux de réunions, les témoignages d’experts, les témoignages de témoins, les jugements, les lettres rogatoires, les entrevues et plus encore – essentiellement, les activités liées aux affaires.
            </p>
              </li>
            <li>
              <p>
                <strong>Traduction juridique.</strong> Il comprend la traduction de certificats de naissance et de certificats de mariage, la traduction de contrats, d’accords, de traités, de mémorandums et de testaments.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduction littéraire.</strong> Elle fait référence à la traduction d’œuvres littéraires comme des histoires, des romans, des poèmes et des pièces de théâtre.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduction médicale.</strong>  Il s’agit de tout contenu médical lié au patient, comme les étiquettes, l’emballage, les instructions ou les logiciels, et du contenu lié au produit, comme les documents de recherche, les documents d’essais cliniques, les certificats de gestion de la qualité et autres, qui doit habituellement être traduit.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduction de script.</strong> Il est vital de nos jours en raison de nombreux films et émissions de télévision populaires venant de Hollywood et étant doublés en plusieurs langues et publié dans le monde entier.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Traduction technique.</strong> Il comprend des guides d’utilisation, des manuels, des textes d’aide en ligne, des livrets d’instructions, du matériel de formation et des vidéos, du matériel de marketing pour des domaines techniques comme la fabrication, les sciences ou l’ingénierie.
              </p>
            </li>
            <li>
              <p>
                <strong>Traduction de sites Web.</strong> Il s’agit de la copie de sites Web, des sous-titres de vidéos sur vos pages Web et de tous les documents que vous y avez.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col></v-col>
      </v-col>
    </v-row>
    <br />
    <v-parallax
      style="margin-bottom: -65px"
      height="300"
      src="ultima-servizi.jpg"
    >
    </v-parallax>
    <br />
    <br />
  </v-main>
</template>


<script>
export default {
  data: () => ({}),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
