import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeEn from '../views/HomeEn.vue'
import AboutEn from '../views/AboutEn.vue'
import ServicesEn from '../views/ServicesEn.vue'
import SpecialisationsEn from '../views/SpecialisationsEn.vue'
import HomeIt from '../views/HomeIt.vue'
import AboutIt from '../views/AboutIt.vue'
import ServicesIt from '../views/ServicesIt.vue'
import SpecialisationsIt from '../views/SpecialisationsIt.vue'
import HomeFr from '../views/HomeFr.vue'
import AboutFr from '../views/AboutFr.vue'
import ServicesFr from '../views/ServicesFr.vue'
import SpecialisationsFr from '../views/SpecialisationsFr.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeEn',
    component: HomeEn
  },
  {
    path: '/AboutEn',
    name: 'aboutEn',
    component: AboutEn
  },
  {
    path: '/ServicesEn',
    name: 'servicesEn',
    component: ServicesEn
  },
  {
    path: '/SpecialisationsEn',
    name: 'specialisationsEn',
    component: SpecialisationsEn
  },
  {
    path: '/HomeIt',
    name: 'homeIt',
    component: HomeIt
  },
  {
    path: '/AboutIt',
    name: 'aboutIt',
    component: AboutIt
  },
  {
    path: '/ServicesIt',
    name: 'servicesIt',
    component: ServicesIt
  },
  {
    path: '/SpecialisationsIt',
    name: 'specialisationsIt',
    component: SpecialisationsIt
  },
  {
    path: '/HomeFr',
    name: 'homeFr',
    component: HomeFr
  },
  {
    path: '/AboutFr',
    name: 'aboutFr',
    component: AboutFr
  },
  {
    path: '/ServicesFr',
    name: 'servicesFr',
    component: ServicesFr
  },
  {
    path: '/SpecialisationsFr',
    name: 'specialisationsFr',
    component: SpecialisationsFr
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
