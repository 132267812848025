<template>
  <v-main>
    <v-parallax src="background.png">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h4 style="color: black; font-size: 30px">Clarissa Bottos</h4>
          <div style="color: black; font-size: 50px">
            Interprète & Traductrice
          </div>
          <div style="color: black">
            Je traduis votre voix dans mes langues
          </div>
          <div style="color: black">Italien | Français | Anglais</div>
          <br />
          <br />
          <v-btn href="#readMore" color="black"
            ><div style="color: white">En savoir plus</div></v-btn
          >
        </v-col>
      </v-row>
    </v-parallax>
    <div id="En savoir plus">
      <v-card
        height="380"
        class="text-center"
        color="#2595AB"
        v-if="!isMobile()"
      >
        <br />
        <br />
        <br />
        <br />
        <v-row style="color: white; font-size: 20px">
          <v-col>
            <h3>Interprétation simultanée, consécutive et de liaison</h3>
            Interprète pour conférences, congrès, expositions, réunions, cours de formation et salons professionnels sur place et à distance
          </v-col>
          <v-col>
            <h3>Adaptation des dialogues, localisation et traduction</h3>
            Traductrice pour textes juridiques/judiciaires, littéraires/éditoriaux, techniques/scientifiques, adaptation des dialogues et localisation
          </v-col>
        </v-row>
        <v-row style="color: white">
          <v-col>
            <h3>Cours de langue</h3>
            Tant pour les entreprises que pour les particuliers, des niveaux de compétence les plus élémentaires aux plus avancés
            </v-col
          ><v-col>
            <h3>Transcription</h3>
            Transcription de fichiers audio et vidéo
          </v-col>
        </v-row>
      </v-card>

      <v-card
        height="600"
        class="text-center"
        color="#2595AB"
        v-if="isMobile()"
      >
        <v-row style="color: white">
          <v-col>
            <h3>Interprétation simultanée, consécutive et de liaison</h3>
            Interprète pour conférences, congrès, expositions, réunions, cours de formation et salons professionnels sur place et à distance
          </v-col>
          <v-col>
            <h3>Adaptation des dialogues, localisation et traduction</h3>
            Traductrice pour textes juridiques/judiciaires, littéraires/éditoriaux, techniques/scientifiques, adaptation des dialogues et localisation
          </v-col>
        </v-row>
        <v-row style="color: white">
          <v-col>
            <h3>Cours de langue</h3>
            Tant pour les entreprises que pour les particuliers, des niveaux de compétence les plus élémentaires aux plus avancés</v-col
          ><v-col>
            <h3>Transcription</h3>
            Transcription de fichiers audio et vidéo
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-row>
      <v-col>
        <v-container>
          <v-col v-if="!isMobile()">
            <v-row>
              <v-col>
                <v-card style="margin-left: " width="270">
                  <v-img src="clarissa2-home.jpg" width="270px" eager> </v-img>
                </v-card>
              </v-col>
              <v-col>
                <v-card style="margin-left: " width="270">
                  <v-img src="clarissa-whoami.jpg" width="270px" eager> </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isMobile()" align="center">
            <v-card width="300">
              <v-img src="clarissa2-home.jpg" width="300px" eager> </v-img>
            </v-card>
            <v-card width="300">
              <v-img src="clarissa-whoami.jpg" width="300px" eager> </v-img>
            </v-card>
          </v-col>
        </v-container>
      </v-col>
      <v-col>
        <v-container style="margin-top: 50px">
          <h1>Salut!</h1>
          <h1>Je suis Clarissa Bottos</h1>
          <br />
          Je suis curieuse et déterminée. Toujours à la recherche de nouveaux défis.
          <br />
          <br />

          Je suis <strong>attentive</strong>, <strong>efficace</strong> et
          <strong>ponctuelle</strong> au travail.
          <br />
          <br />
          <div>
            Je vis à Rome, mais je suis citoyenne du monde. J’ai vécu à Paris et j’ai voyagé sur cinq continents. Je suis disponible pour les voyages nationaux et internationaux.
          </div>
        </v-container>
        <v-row v-if="!isMobile()" style="margin-left: 3px">
          <v-col>
            <v-btn
              href="https://www.linkedin.com/in/clarissa-bottos-4985a01b9/"
              color="black"
              ><div style="color: white">Me connaître mieux</div></v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="isMobile()">
          <v-col align="center">
            <v-btn
              href="https://www.linkedin.com/in/clarissa-bottos-4985a01b9/"
              color="black"
              ><div style="color: white">Me connaître mieux</div></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div style="margin-top: 20px">
      <v-card
        v-if="!isMobile()"
        height="200"
        class="text-center"
        color="#2595AB"
      >
      <br>
      <br>
        <v-row>
          <v-col>
          <v-col class="text-center" >
            <h4 style="color: white; font-size: 25px;">
              "Une langue vous place sur un couloir pour la vie.
            </h4>
            <h4 style="color: white; font-size: 25px;">
              Deux langues ouvrent toutes les portes sur le chemin."
            </h4>
          </v-col>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="isMobile()"
        height="135"
        class="text-center"
        color="#2595AB"
      >
        <v-row align="center" justify="center" align-content="center">
          <v-col>
          <v-col class="text-center" >
            <h4 style="color: white;">
              "Une langue vous place sur un couloir pour la vie.
            </h4>
            <h4 style="color: white;">
              Deux langues ouvrent toutes les portes sur le chemin."
            </h4>
          </v-col>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <br />
    <br />
    <v-container
      v-if="!isMobile()"
      height="100"
      class="text-center"
      color="#2595AB"
    >
      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col class="text-center">
          <h3 style="color: black; font-size: 45px">J'ai travaillé pour</h3>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="isMobile()"
      height="100"
      class="text-center"
      color="#2595AB"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h3 style="color: black; font-size: 30px">J'ai travaillé pour</h3>
        </v-col>
      </v-row>
    </v-container>

    <v-carousel
      cycle
      height="300"
      hide-delimiter-background
      :show-arrows="false"
      eager
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
        <v-sheet height="100%">
          <v-row class="fill-height" align="center" justify="center">
            <v-card class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[0]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>
            <v-card v-if="!isMobile()" class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[1]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>

            <v-card v-if="!isMobile()" class="mx-auto" max-width="200">
              <v-img eager class="white--text align-end" :src="slide[2]">
              </v-img>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <br />
    <v-parallax style="margin-bottom: -17px" height="250" src="background.png">
      <v-row align="center" justify="center">
        <v-col class="text-center" style="color: black">
          <div style="font-size: 25px" v-if="!isMobile()">
            Vous voulez créer quelque chose de grand ensemble?
          </div>
          <h3 v-if="isMobile()">Vous voulez créer quelque chose de grand ensemble?</h3>
          <div>Faisons en sorte que ça arrive.</div>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row style="margin-top: 5px;">
      <v-col> </v-col>
      <v-col>
          <v-card style="width: 400px">
            <v-img eager src="clarissa-fao.jpeg" width="400px"> </v-img>
          </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-main>
</template>


<script>
export default {
  data: () => ({
    slides: [
      ["logo-fao.png", "villa-medici.jpg", "italian-chef-academy.png"],
      ["villa-medici.jpg", "italian-chef-academy.png", "logo-ita-dance.png"],
      ["italian-chef-academy.png", "logo-ita-dance.png", "logo-amb-peru.png"],
      ["logo-ita-dance.png", "logo-amb-peru.png", "logoAmb-Italie.jpg"],
      ["logo-amb-peru.png", "logoAmb-Italie.jpg", "fit-csl.jpg"],
      ["logoAmb-Italie.jpg", "fit-csl.jpg", "logo-fao.png"],
      ["fit-csl.jpg", "logo-fao.png", "villa-medici.jpg"],
      ["logo-fao.png", "villa-medici.jpg","italian-chef-academy.png"]
    ],
  }),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
