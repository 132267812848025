<template>
  <v-main style="margin-top: 13px;">
    <v-card height="220" class="text-center" color="#2595AB">
      <div>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <h1 v-if="!isMobile()" style="color: white; margin-top: 40px; font-size: 80px">
              Chi sono
            </h1>
            <h1 v-if="isMobile()" style="color: white; margin-top: 60px; font-size: 50px">
              Chi sono
            </h1>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-container>
          <v-card v-if="!isMobile()" style="width: 500px; margin-left: 100px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
          <v-card v-if="isMobile()" style="width: 500px">
            <v-img eager src="clarissa-about.jpg" width="500px"> </v-img>
          </v-card>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <div>
            <h1 style="font-size: 50px">Clarissa Bottos</h1>
            <p style="font-size: 19.5px">
              Interprete e traduttrice, il mio compito è quello di costruire ponti linguistici e culturali tra persone parlanti lingue diverse. La passione per le lingue proviene da mia madre che è stata la mia guida sin da piccina. Ai miei genitori sarò sempre grata perché mi hanno permesso di viaggiare fin da subito, lasciandomi scoprire il mondo, le varie culture e le lingue a esso correlate. É stato questo, insieme agli studi che ho compiuto, a permettermi di diventare la professionista che sono oggi. Se vuoi saperne di più, scorri in basso per vedere le mie qualifiche!
            </p>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <h1 v-if="!isMobile()" style="font-size: 50px">Percorso accademico e formazione</h1>
      <h1 v-if="isMobile()" style="font-size: 50px;text-align: center;">Percorso accademico e formazione</h1>

      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
      <v-timeline v-if="!isMobile()">
        <v-timeline-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${year.color}--text`"
              v-text="year.year"
            ></span>
          </template>

          <v-card color="white" style="width: 800px">
            <v-card-title
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </v-card-title>
            <v-container v-if="year.subtitle">
              <v-row>
                <v-col cols="12" md="10" style="color: black">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-list v-if="isMobile()">
        <v-list-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
        <v-row>
          <v-col align="">
          <v-container color="white">
            <div>
              <v-row>
                <v-col style="color: black; font-size: 15px">
                  {{ year.year }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
            <div
              class="text-overline mb-4"
              style="word-break: break-word"
            >
              <div class="black--text" style="font-size: 20px">
                {{ year.title }}
              </div>
            </div>
            <div v-if="year.subtitle">
              <v-row>
                <v-col style="color: black; font-size: 20px">
                  {{ year.subtitle }}
                </v-col>
                <v-col class="#2595AB hidden-sm-and-down text-right" md="2">
                </v-col>
              </v-row>
            </div>
          </v-container>
          </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
      <v-col>
          <v-card style="width: 400px">
            <v-img eager src="clarissa-about-2.jpg" width="400px"> </v-img>
          </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-main>
</template>


<script>
export default {
  data: () => ({
    years: [
      {
        color: "#2595AB",
        year: "APRILE 2023",
        title: "Socio aggregato AITI - Associazione Italiana Traduttori e Interpreti"
      },
      {
        color: "#2595AB",
        year: "DICEMBRE 2022",
        title: "Insegnante MIUR accreditata per preparazione esami Cambridge"
      },
      {
        color: "#2595AB",
        year: "OTTOBRE 2022",
        title: "Interprete Verspeak certificata"
      },
      {
        color: "#2595AB",
        year: "2020 - 2022",
        title: "LAUREA MAGISTRALE IN INTERPRETAZIONE DI CONFERENZA",
        subtitle:
          "Università degli Studi Internazionali di Roma, UNINT Roma (Italia)",
      },
      {
        color: "#2595AB",
        year: "GIUGNO 2022",
        title: "TRADUTTRICE VOLONTARIA Ted Conferences",
      },
      {
        color: "#2595AB",
        year: "MAGGIO 2022",
        title: "PUBLIC SPEAKING- CORSO DI ALTA FORMAZIONE",
      },
      {
        color: "#2595AB",
        year: "MAGGIO 2022",
        title: "DIZIONE- CORSO DI ALTA FORMAZIONE",
      },
      {
        color: "#2595AB",
        year: "MAGGIO 2022",
        title: "CROSS CULTURAL COMMUNICATION- CORSO DI ALTA FORMAZIONE",
      },
      {
        color: "#2595AB",
        year: "APRILE 2022",
        title: "INTERPRETE KUDO CERTIFICATA",
      },
      {
        color: "#2595AB",
        year: "APRILE 2022",
        title:
          "INTERPRETE E TRADUTTRICE UMANITARIA Translators without Borders",
      },
      {
        color: "#2595AB",
        year: "2021",
        title: "ERASMUS",
        subtitle:
          "Institute Of Intercultural Communication And Management, ISIT Parigi (Francia)",
      },
      {
        color: "#2595AB",
        year: "APRILE 2021",
        title: "TRADUTTRICE SDL TRADOS",
      },
      {
        color: "#2595AB",
        year: "2017 - 2020",
        title: "LAUREA TRIENNALE IN LINGUE PER L'INTERPRETARIATO E LA TRADUZIONE",
        subtitle:
          "Università degli Studi Internazionali di Roma, UNINT Roma (Italia)",
      },
      {
        color: "#2595AB",
        year: "MAGGIO 2016",
        title: "DELE",
        subtitle: "Instituto Cervantes, Roma (Italia)",
      },
      {
        color: "#2595AB",
        year: "APRILE 2016",
        title: "DALF",
        subtitle: "Institut français Centre Saint-Louis, Roma (Italia)",
      },
      {
        color: "#2595AB",
        year: "MAGGIO 2015",
        title: "FIRST",
        subtitle:
          "Cambridge University Press & Assessment English, Roma (Italia)",
      },
    ],
  }),
  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
